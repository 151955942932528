import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.string.trim.js";

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var proButton = function proButton() {
  return import("../common/proButton");
};

export default {
  components: {
    proButton: proButton
  },
  data: function data() {
    return {
      redirect: this.$route.query.redirect,
      account: "",
      password: ""
    };
  },
  computed: {},
  created: function created() {},
  methods: {
    login: function login() {
      var _this = this;

      if (this.$common.isEmpty(this.account) || this.$common.isEmpty(this.password)) {
        this.$message({
          message: "请输入账号或密码！",
          type: "error"
        });
        return;
      }

      var user = {
        account: this.account.trim(),
        password: this.$common.encrypt(this.password.trim()),
        isAdmin: true
      };
      this.$http.post(this.$constant.baseURL + "/user/login", user, true, false).then(function (res) {
        if (!_this.$common.isEmpty(res.data)) {
          localStorage.setItem("adminToken", res.data.accessToken);

          _this.$store.commit("loadCurrentAdmin", res.data);

          _this.account = "";
          _this.password = "";

          _this.$router.push({
            path: _this.redirect
          });
        }
      }).catch(function (error) {
        _this.$message({
          message: error.message,
          type: "error"
        });
      });
    }
  }
};